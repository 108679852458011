<!-- 编辑账单 -->
<template>
  <div>
    <blockTitle title="编辑账单"></blockTitle>
    <el-card shadow="never">
      <div class="operator mb-4 border-b pb-4">
        <el-button @click="submit" size="small" type="primary"
        >保存账单
        </el-button>
        <el-button @click="goBack" size="small">取消编辑</el-button>
      </div>
      <div class="card-content">
        <el-form
            ref="form"
            :model="billObj"
            label-width="160px"
            label-position="left"
            size="small"
            cass="overflow-auto"
            :rules="rules"
        >
          <el-form-item label="客户" prop="uniqueId">
            <div v-if="billObj.customerName">
              {{ billObj.customerName }}
            </div>
            <el-select
                ref="uniqueIdRef"
                v-model="billObj.uniqueId"
                placeholder="请选择客户"
                v-if="!billObj.id"
                filterable
                @change="uniqueIdOnChange"
            >
              <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :value="item.uniqueId"
                  :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式" prop="payType">
            <el-select v-model="billObj.payType" placeholder="请选择支付方式">
              <el-option
                  v-for="(item, index) in $store.getters.payType"
                  :key="index"
                  :value="item.type"
                  :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账单备注" prop="remark">
            <el-input
                type="textarea"
                :rows="4"
                placeholder="账单备注"
                v-model="billObj.remark"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="产品规格">
            <template v-if="billObj.uniqueId">
              <el-table
                  :summary-method="getSum"
                  show-summary
                  :data="billObj.billDetailList"
                  style="width: 100%"
              >
                <el-table-column prop="projectType" label="项目类型">
                  <template v-slot="{ row, $index }">
                    <el-select
                        v-model="row.projectType"
                        @change="projectTypeOnChange($index, $event)"
                    >
                      <el-option
                          v-for="(item, index) in $store.getters.projectType"
                          :key="index"
                          :value="item.type"
                          :label="item.label"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="billProjectId" label="产品规格">
                  <template v-slot="{ row, $index }">
                    <div></div>
                    <el-select
                        v-model="row.billProjectId"
                        @change="computedRowMoney($index)"
                        filterable
                    >
                      <el-option
                          v-for="(item, index) in getFilterListByType(
                          row.projectType
                        )"
                          :key="index"
                          :value="item.id"
                          :label="item.projectName"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="项目单价">
                  <template v-slot="{ row }">
                    {{ getProjectItem(row.billProjectId).unitPrice }}元 /
                    {{ getProjectItem(row.billProjectId).unit }}
                  </template>
                </el-table-column>
                <el-table-column prop="quantity" label="数量">
                  <template v-slot="{ row, $index }">
                    <el-input-number
                        :disabled="!row.billProjectId"
                        v-model="row.quantity"
                        @change="computedRowMoney($index, $event)"
                        :min="1"
                        label="请输入数量"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column prop="totalFee" label="总金额">
                  <template v-slot="{ row }">
                    <el-input-number
                        :disabled="!row.billProjectId"
                        v-model="row.totalFee"
                        :min="0"
                        label="请输入金额"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column width="120px" label="#">
                  <template v-slot="{ $index }">
                    <el-button
                        @click="removeProject($index)"
                        icon="el-icon-delete"
                        type="danger"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-button class="mt-4" @click="pushProject" size="mini"
              >添加产品规格
              </el-button>
            </template>
            <template v-else>
              <div class="tips text-sm text-red-500 pt-2">请先选择客户</div>
            </template>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      billObj: {},
      rules: {
        payType: [
          {
            required: true,
            message: '请选择支付方式',
            trigger: 'blur'
          }
        ]
      },
      userList: [],
      projectModel: {
        // 项目类型
        projectType: '',
        // 产品规格ID
        billProjectId: '',
        // 项目名称
        projectName: '',
        // 数量
        quantity: 0,
        // 总金额
        totalFee: 0,
        // 单位
        unit: ''
      },
      billProjectList: [],
      billDeviceList: [],
      billLicenseList: [],
      billAppVersionList: [],
      allBillProjectList: [],
      tmpUserKey: ''
    }
  },
  methods: {
    initData () {
      this.billObj = {
        uniqueId: '',
        payType: 1,
        billDetailList: [],
        remark: ''
      }
    },
    getUserList () {
      this.$api.findAll('customerInfo').then(res => {
        this.userList = res.data
      })
    },
    pushProject () {
      if (this.checkProjectListNullItem()) {
        this.billObj.billDetailList.push(JSON.parse(JSON.stringify(this.projectModel)))
      } else {
        this.$message.error('请选择产品规格')
      }
    },
    removeProject (index) {
      this.billObj.billDetailList.splice(index, 1)
    },
    getBillProjectList ({ uniqueId }) {
      this.$api.findAll('billProject', {
        uniqueId: uniqueId
      }).then(res => {
        this.allBillProjectList = res.data
        this.allBillProjectList.forEach(item => {
          switch (item.projectType) {
            case 2:
              this.billProjectList.push(item)
              break
            case 3:
              this.billDeviceList.push(item)
              break
            case 4:
              this.billLicenseList.push(item)
              break
            case 5:
              this.billAppVersionList.push(item)
              break
          }
        })
      })
    },
    getFilterListByType (type) {
      switch (type) {
        case 2:
          return this.billProjectList
        case 3:
          return this.billDeviceList
        case 4:
          return this.billLicenseList
        case 5:
          return this.billAppVersionList
      }
    },
    projectTypeOnChange (index) {
      this.billObj.billDetailList[index].billProjectId = ''
    },
    /**
     * 计算当行总价
     * @param index
     */
    computedRowMoney (index) {
      const item = this.billObj.billDetailList[index]
      if (item) {
        const unitPrice = this.getProjectItem(item.billProjectId).unitPrice
        item.totalFee = unitPrice * item.quantity
      }
    },
    getProjectItem (billProjectId) {
      const item = this.allBillProjectList.find(item => item.id === billProjectId)
      if (item) {
        return item
      }
      return {
        unitPrice: '-'
      }
    },
    /**
     * 计算合计
     */
    getSum ({
      columns,
      data
    }) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        if (column.property === 'totalFee') {
          if (data.length > 0) {
            const totalFeeArr = data.map(item => item.totalFee)
            sums[index] = totalFeeArr.reduce((prev, curr, index) => {
              const row = data[index]
              if (row.billProjectId) {
                // 如果没有选择产品规格 不参与合计计算
                return prev + curr
              } else {
                return prev
              }
            })
            sums[index] += ' 元'
          }
        }
      })
      return sums
    },
    /**
     * 检查是否有未选择的产品规格
     * true 表示通过 即为没有未选择的
     * @returns {boolean}
     */
    checkProjectListNullItem () {
      return !this.billObj.billDetailList.find(item => !item.billProjectId)
    },
    validProjectList () {
      if (this.billObj.billDetailList.length === 0) {
        this.$message.error('至少添加一条账单')
        return false
      } else if (!this.checkProjectListNullItem()) {
        this.$message.error('请选择产品规格')
        return false
      } else {
        return true
      }
    },
    submit () {
      if (!this.billObj.uniqueId) {
        this.$message.error('请选择客户')
        return false
      }
      this.$refs.form.validate(valid => {
        if (valid && this.validProjectList()) {
          const loading = this.$loading({
            lock: true,
            text: '请稍候',
            spinner: 'el-icon-loading'
          })
          this.$api.save('bill', this.billObj).then(() => {
            this.$message.success('操作成功')
            this.goBack()
          }).finally(() => {
            loading.close()
          })
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    },
    getData () {
      this.$api.select('bill', this.$route.query.id).then(res => {
        this.billObj = res.data
      })
    },
    /**
     * 清空所有产品规格
     */
    initFilterListByType () {
      this.billProjectList = []
      this.billDeviceList = []
      this.billLicenseList = []
      this.billAppVersionList = []
    },
    /**
     * 清空表格内的产品规格
     */
    initProject () {
      this.billObj.billDetailList = []
    },
    uniqueIdOnChange (uniqueId) {
      if (this.billObj.billDetailList.length > 0) {
        // 已经选择了产品规格 需要提示下方内容将会被清空
        this.$confirm('已经选择了产品规格,下方内容将会被清空,是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.initFilterListByType()
          this.initProject()
          this.getBillProjectList({ uniqueId })
          this.tmpUserKey = JSON.parse(JSON.stringify(uniqueId))
        }).catch(() => {
          // 选择的不更换代理
          this.billObj.agUserKey = this.tmpUserKey
        }).finally(() => {
          // 主动关闭下拉框选项
          this.$refs.uniqueIdRef.blur()
        })
      } else {
        this.tmpUserKey = JSON.parse(JSON.stringify(uniqueId))
        this.getBillProjectList({ uniqueId })
      }
    }
  },
  mounted () {
    this.initData()
    this.getUserList()
    if (this.$route.query.id) {
      this.getData()
    }
  }
}
</script>
